import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'

@Component({
  selector: 'app-uplift-detail',
  templateUrl: './uplift-detail.component.html'
})
export class UpliftDetailComponent implements OnInit {
  upliftDetails: any
  previewUrl: string = ''
  linkPreview: any
  upliftId: string
  report: boolean = false

  constructor(private toasterService: ToastrService, private allService: AllService, private activeRoute: ActivatedRoute) {
    this.activeRoute.queryParams.subscribe((params) => {
      this.upliftId = params['upliftId'] || null
      this.report = params['report'] || false
    })
  }

  ngOnInit(): void {
    this.allService.getUpliftDetails(this.upliftId).subscribe((res: any) => {
      if (res.errorStatus) {
        this.toasterService.error(res.error)
        return
      }
      this.upliftDetails = res.payload
      this.extractUrl(this.upliftDetails.post)
      this.upliftDetails.post = this.formatPostText(this.upliftDetails.post)
    })
  }
  extractUrl(post: string): void {
    const urls = post?.replace(/(https?:\/\/[^\s/$.?#].[^\s]*)/g, ', $1 ,').match(/(https?:\/\/[^\s]+)/g) || []
    if (urls.length) {
      this.previewUrl = urls.pop()
      this.allService.getPreviewDetails(this.previewUrl).subscribe((res: any) => {
        if (res.errorStatus) {
          return
        }
        this.linkPreview = res.payload.metadata
      })
    } else {
      this.previewUrl = ''
      this.linkPreview = ''
    }
  }
  formatPostText(post: string): string {
    return post.replace(/(https?:\/\/[^\s]+)/g, '<br><a href="$1" target="_blank">$1</a>')
  }
  goBack(): void {
    window.history.back()
  }
}
