import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, FormBuilder } from '@angular/forms'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { error } from 'src/app/_helpers/error'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted: boolean = false
  error = error.err
  loginForm: FormGroup
  hide = true
  hide1 = true
  hide2 = true
  togglePass = true
  userType: any = 1
  check: number = 1
  receiveEmail: any

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService, private fb: FormBuilder) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: new FormControl('', []),
      password: new FormControl('', [])
    })
    let a = localStorage.getItem('receiveEmail')
    let b = a?.slice(1, a.length - 1)
    this.loginForm.get('email').patchValue(b)
  }

  get f() {
    return this.loginForm.controls
  }

  onAdminSelection(event: any) {
    if (event.target.checked) {
      this.userType = 1
      this.check = 1
    }
  }

  onSupportSelection(event: any) {
    if (event.target.checked) {
      this.userType = 5
      this.check = 2
    }
  }

  onZoneSelection(event: any) {
    if (event.target.checked) {
      this.userType = 6
      this.check = 3
    }
  }

  login() {
    this.submitted = true
    if (this.loginForm.invalid) return
    this.submitted = false
    let user: any = {
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password
    }
    this.authService.login(user).subscribe((res: any) => {
      if (res.errorStatus) {
        this.toastr.error(res.error)
        return
      }
      localStorage.setItem('currentUser', JSON.stringify(res.payload?.accessToken))
      this.authService.setCurrentUserObj(res.payload)
      this.router.navigateByUrl('/dashboard')
    })
  }
}
