import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { AuthGuard } from './_helpers/auth.guard.service'
import { DashboardComponent } from './container/dashboard/dashboard.component'
import { PasswordChangeComponent } from './container/password-change/password-change.component'
import { AffirmationComponent } from './container/affirmation/affirmation.component'
import { JournalQuestionsComponent } from './container/journal-questions/journal-questions.component'
import { LayoutComponent } from './shared/layout/layout.component'
import { UpliftComponent } from './container/uplift/uplift.component'
import { UpliftDetailComponent } from './container/uplift-detail/uplift-detail.component'
import { ReportedUserComponent } from './container/reported-user/reported-user.component'
import { ReportedUpliftComponent } from './container/reported-uplift/reported-uplift.component'
import { ReportedUserDetailComponent } from './container/reported-user-detail/reported-user-detail.component'
import { ReportUpliftDetailComponent } from './container/report-uplit-detail/report-uplift-detail.component'
import { LeaderboardComponent } from './container/leaderboard/leaderboard.component'

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'affirmation', component: AffirmationComponent },
      { path: 'password-change', component: PasswordChangeComponent },
      { path: 'journal-question', component: JournalQuestionsComponent },
      { path: 'uplift', component: UpliftComponent },
      { path: 'uplift-detail', component: UpliftDetailComponent },
      { path: 'reported-users', component: ReportedUserComponent },
      { path: 'reported-user-detail', component: ReportedUserDetailComponent },
      { path: 'reported-uplift-detail', component: ReportUpliftDetailComponent },
      { path: 'reported-uplifts', component: ReportedUpliftComponent },
      { path: 'leaderboard', component: LeaderboardComponent }
    ]
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
