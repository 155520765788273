import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AllService {
  url: any = environment.API_URL

  constructor(private http: HttpClient) {}
  validateOldPassword(data: any) {
    return this.http.post(`${this.url}/old-pass-validate`, data)
  }
  updatePassword(data: any) {
    return this.http.post(`${this.url}/pass-update`, data)
  }
  getDefaultAffirmation() {
    return this.http.get(`${this.url}/default-aff-list`)
  }
  updateAffirmation(affData: object) {
    return this.http.put(`${this.url}/default-aff`, affData)
  }
  getAllJournalQuestion() {
    return this.http.get(`${this.url}/journal-question`)
  }
  addJournalQuestion(data: any) {
    return this.http.post(`${this.url}/add-journal-question`, data)
  }
  updateJournalQuestion(data: object) {
    return this.http.put(`${this.url}/journal-question`, data)
  }
  getDashboardReport() {
    return this.http.get(`${this.url}/dashboard`)
  }
  getUplift(page: number, limit: number) {
    return this.http.get(`${this.url}/uplift/list`, { params: { page, limit } })
  }
  getSearchedUser(searchKey: string) {
    return this.http.get(`${this.url}/searched-user`, { params: { searchKey } })
  }
  createUplift(data: any) {
    return this.http.post(`${this.url}/uplift/create`, data)
  }
  getUpliftDetails(upliftId: string) {
    return this.http.get(`${this.url}/uplift/details`, { params: { upliftId } })
  }
  updateUplift(data: any) {
    return this.http.put(`${this.url}/uplift/update`, data)
  }
  deleteUplift(upliftId: string) {
    return this.http.delete(`${this.url}/uplift/delete`, { params: { upliftId } })
  }
  getPreviewDetails(url: string) {
    return this.http.post(`${this.url}/fetch-preview`, { url })
  }
  getAllReports(page: number, limit: number, type: string) {
    return this.http.get(`${this.url}/blocked-report`, { params: { page, limit, type } })
  }
  getBlockedUserWithUserList(blockedId: string) {
    return this.http.get(`${this.url}/blocked-user-detail`, { params: { blockedId } })
  }
  getBlockedUpliftWithUserList(blockedId: string) {
    return this.http.get(`${this.url}/blocked-uplift-detail`, { params: { blockedId } })
  }
  // update-user-status
  updateUserStatus(blockedId: string, status: string) {
    return this.http.post(`${this.url}/update-user-status`, { blockedId, status })
  }
  // update-uplift-status
  updateUpliftStatus(blockedId: string, status: string) {
    return this.http.post(`${this.url}/update-uplift-status`, { blockedId, status })
  }
  getLeaderBoardData(page: number, limit: number) {
    return this.http.get(`${this.url}/leaderboard/list`, { params: { page, limit } })
  }
  resetLeaderBoard() {
    return this.http.put(`${this.url}/leaderboard/reset`, { reset: true })
  }
}
