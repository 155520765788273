<div class="sidebar">
  <div class="m-0 row">
    <div class="col-12 d-flex mb-3 mt-3 p-0" style="height: 95px">
      <div class="d-flex justify-content-center w-100">
        <img src="../../../assets/maunda/logo.svg" width="100" alt="logo" />
      </div>
    </div>

    <div class="col-12 header-links m-0 p-0">
      <a routerLink="/dashboard" routerLinkActive="active" class="align-items-center d-flex">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/dashboard-white.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/dashboard-black.svg" alt="" height="24" width="24" /> </span
        >Dashboard</a
      >

      <a routerLink="/affirmation" routerLinkActive="active" class="align-items-center d-flex">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/affirmation-white.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/affirmation-black.svg" alt="" height="24" width="24" /> </span
        >Affirmation</a
      >

      <a routerLink="/leaderboard" routerLinkActive="active" class="align-items-center d-flex">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/leaderboard-white.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/leaderboard-black.svg" alt="" height="24" width="24" /> </span
        >Leaderboard</a
      >

      <a routerLink="/uplift" routerLinkActive="active" class="align-items-center d-flex">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/uplift-black.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/uplift-white.svg" alt="" height="24" width="24" /> </span
        >Uplift</a
      >

      <a routerLink="/journal-question" routerLinkActive="active" class="align-items-center d-flex">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/journal-white.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/journal-black.svg" alt="" height="24" width="24" /> </span
        >Journal Questions</a
      >

      <a routerLink="/reported-users" routerLinkActive="active" class="align-items-center d-flex">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/report-white.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/report-black.svg" alt="" height="24" width="24" /> </span
        >Reported Users</a
      >

      <a routerLink="/reported-uplifts" routerLinkActive="active" class="align-items-center d-flex">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/report-white.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/report-black.svg" alt="" height="24" width="24" /> </span
        >Reported Uplifts</a
      >
      <a routerLink="/password-change" routerLinkActive="active" class="align-items-center d-flex">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/password-white.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/password-black.svg" alt="" height="24" width="24" /> </span
        >Change Password</a
      >

      <a routerLinkActive="active" (click)="openModal(template)" class="align-items-center d-flex" style="cursor: pointer">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/maunda/logout-white.svg" alt="" height="24" width="24" />
          <img class="white-icon" src="../../../assets/maunda/logout-black.svg" alt="" height="24" width="24" /> </span
        >Logout</a
      >
    </div>
  </div>
</div>

<!-- Logout confirm -->
<ng-template #template>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../assets/maunda/create-successfull-img.svg" alt="" />
    <p>Are you sure you want to logout?</p>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-cancel ms-2" style="width: 100px" (click)="cancel()">No</button>
    <button class="btn btn-green ms-2" style="width: 100px" (click)="confirm()">Yes</button>
  </div>
</ng-template>
