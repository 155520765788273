import { Component, TemplateRef, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  submitted: boolean = false
  modalRef: BsModalRef

  @ViewChild('template') template: TemplateRef<any>
  constructor(private authService: AuthService, private router: Router, private modalService: BsModalService, private toastr: ToastrService) {}

  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  get f() {
    return this.resetForm.controls
  }

  sendEmailToLogin: any

  resetPassword() {
    this.submitted = true
    if (this.resetForm.invalid || this.resetForm.pristine) return
    this.submitted = false

    this.authService.forgetPassword({ email: this.resetForm.value.email.toLowerCase() }).subscribe((res: any) => {
      if (res.errorStatus) {
        this.toastr.error(res.error)
        return
      }
      this.modalRef = this.modalService.show(this.template, {
        ignoreBackdropClick: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }

  OkPress() {
    this.modalRef.hide()
    this.router.navigateByUrl('/login')
  }
}
