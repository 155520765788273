import { BrowserModule } from '@angular/platform-browser'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ToastrModule } from 'ngx-toastr'
import { NgHttpLoaderModule } from 'ng-http-loader'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { ModalModule } from 'ngx-bootstrap/modal'
import { CustomFormsModule } from 'ng2-validation'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { MatSelectModule } from '@angular/material/select'
import { PaginationModule } from 'ngx-bootstrap/pagination'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { NgxChartsModule } from '@swimlane/ngx-charts'

import { AppRoutingModule } from './app-routing.module'
import { AuthModule } from './auth/auth.module'
import { SharedModule } from './shared/shared.module'
import { AppComponent } from './app.component'
import { AuthInterceptor } from './_helpers/auth.interceptor'
import { AuthService } from './auth/services/auth.service'
import { AuthGuard } from './_helpers/auth.guard.service'
import { SessionService } from './shared/services/session.service'
import { DashboardComponent } from './container/dashboard/dashboard.component'
import { PasswordChangeComponent } from './container/password-change/password-change.component'
import { AffirmationComponent } from './container/affirmation/affirmation.component'
import { JournalQuestionsComponent } from './container/journal-questions/journal-questions.component'
import { UpliftComponent } from './container/uplift/uplift.component'
import { UpliftDetailComponent } from './container/uplift-detail/uplift-detail.component'
import { ReportedUpliftComponent } from './container/reported-uplift/reported-uplift.component'
import { ReportedUserComponent } from './container/reported-user/reported-user.component'
import { ReportedUserDetailComponent } from './container/reported-user-detail/reported-user-detail.component'
import { ReportUpliftDetailComponent } from './container/report-uplit-detail/report-uplift-detail.component'
import { LeaderboardComponent } from './container/leaderboard/leaderboard.component'

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    PasswordChangeComponent,
    AffirmationComponent,
    JournalQuestionsComponent,
    UpliftComponent,
    UpliftDetailComponent,
    ReportedUpliftComponent,
    ReportedUserComponent,
    ReportedUserDetailComponent,
    ReportUpliftDetailComponent,
    LeaderboardComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({ preventDuplicates: true, progressAnimation: 'increasing', progressBar: true, timeOut: 2000 }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    CustomFormsModule,
    NgxSliderModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxChartsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, AuthGuard, AuthService, SessionService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
