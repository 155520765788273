import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit, TemplateRef } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html'
})
export class LeaderboardComponent implements OnInit {
  modalRef: BsModalRef
  leaderboard: any = []
  loading: boolean = true

  totalCount: number = 0
  limit: number = 10
  currentPage: number = 1

  constructor(private toasterService: ToastrService, private allService: AllService, private activeRoute: ActivatedRoute, private modalService: BsModalService) {}

  ngOnInit(): void {
    this.getLeaderBoard(this.currentPage, this.limit)
  }

  resetLeaderBoard(): void {
    this.allService.resetLeaderBoard().subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.toasterService.success('Leaderboard has been reset successfully')
        this.modalRef.hide()
        this.currentPage = 1
        this.getLeaderBoard(this.currentPage, this.limit)
      },
      (err: HttpErrorResponse) => {
        this.modalRef.hide()
      }
    )
  }

  openConfirmationModel(popUp: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(popUp, { ignoreBackdropClick: true, backdrop: 'static', keyboard: true })
  }

  getLeaderBoard(page: number, limit: number): void {
    this.allService.getLeaderBoardData(page, limit).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.leaderboard = res.payload.leaderBoard
        this.totalCount = res.payload.totalCount
        this.loading = false
      },
      (err: HttpErrorResponse) => {
        this.loading = false
      }
    )
  }
  pageChanged(event: any): void {
    this.getLeaderBoard(event.page, event.itemsPerPage)
  }
}
