import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http'
import { AuthService } from '../auth/services/auth.service'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { tap } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, public router: Router, private toasterService: ToastrService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue
    const userid = this.authService.CurrentUserObj
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: currentUser,
          userid
        }
      })
    }
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            if (event.body.status == 401) {
              this.redirectToLoginScreen()
            }
          }
        },
        (error) => {
          if (error.status == 401) {
            this.redirectToLoginScreen()
          } else {
            this.toasterService.error(error.error?.error || error.message)
          }
        }
      )
    )
  }
  redirectToLoginScreen() {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('userid')
    this.router.navigate(['/auth/login'])
    this.toasterService.error('Session expired please re-login again')
  }
}
