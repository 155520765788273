<div class="content">
  <div class="d-flex float-end justify-content-between mb-2">
    <button class="btn btn-primary" (click)="openConfirmationModel(delModel)">Reset Leaderboard</button>
  </div>
  <div class="table-responsive bg-white mb-2">
    <table class="table border table-hover rounded" aria-describedby="default-uplift">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Name</th>
          <th>Email</th>
          <th class="text-center">Phone Number</th>
          <th class="text-center">Score</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of leaderboard; let i = index">
          <td>{{ (currentPage - 1) * limit + (i + 1) }}</td>
          <td class="d-flex gap-1">
            <ng-container *ngIf="user?.profilePicUrl; else defaultProfile">
              <img src="{{ user?.profilePicUrl }}" alt="profile" height="50" width="50" class="rounded" />
            </ng-container>
            <ng-template #defaultProfile>
              <img src="../../../assets/maunda/user-default.png" alt="profile" height="50" width="50" class="rounded" />
            </ng-template>
            <div>
              <p class="m-0 p-0">{{ user?.name || '-' }}</p>
              <p class="m-0 p-0">&#64;{{ user?.username || '' }}</p>
            </div>
          </td>
          <td>{{ user?.email || '' }}</td>
          <td class="text-center">
            {{ user?.mobileCountryCode && user?.phoneNumber ? (user?.mobileCountryCode == 'null' ? '' : user.mobileCountryCode) : ''
            }}{{ user?.phoneNumber ? (user?.phoneNumber == 'null' ? '-' : user.phoneNumber) : '-' }}
          </td>
          <td class="text-center">{{ user?.score || '0' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center" *ngIf="!leaderboard.length && !loading">
    <span style="font-size: 25px; padding: 2rem">No user in leaderboard!</span>
  </div>

  <pagination
    *ngIf="leaderboard.length && totalCount > limit"
    [boundaryLinks]="true"
    [totalItems]="totalCount"
    [maxSize]="3"
    [itemsPerPage]="limit"
    [(ngModel)]="currentPage"
    (pageChanged)="pageChanged($event)"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
  >
  </pagination>
</div>

<ng-template #delModel>
  <div class="modal-header">
    <div class="d-flex justify-content-center w-100">
      <h2 class="m-0 p-0">Reset Leaderboard</h2>
    </div>
  </div>
  <div class="modal-body text-center delete-modal">
    <img src="../../../assets/maunda/delete-img.svg" alt="" />
    <p>Are you sure you want to reset the leaderboard for current month?</p>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-cancel" (click)="modalRef.hide()">Cancel</button>
    <button class="btn btn-green ms-2" (click)="resetLeaderBoard()">Confirm</button>
  </div>
</ng-template>
