import { Component, OnInit, TemplateRef } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'
import { PageChangedEvent } from 'ngx-bootstrap/pagination'
import { Router } from '@angular/router'
@Component({
  selector: 'app-reported-uplift',
  templateUrl: './reported-uplift.component.html'
})
export class ReportedUpliftComponent implements OnInit {
  totalCount: number = 0
  limit: number = 10
  currentPage: number = 1

  blockedReports: any = []
  modalRef: BsModalRef
  loading: boolean = true
  selectedReport: any

  constructor(private toasterService: ToastrService, private allService: AllService, private modalService: BsModalService, private route: Router) {}

  ngOnInit(): void {
    this.getAllReports(this.currentPage, this.limit)
  }

  pageChanged(event: PageChangedEvent): void {
    this.getAllReports(event.page, event.itemsPerPage)
  }

  getAllReports(page: number, limit: number): void {
    this.allService.getAllReports(page, limit, 'uplift').subscribe(
      (res: any) => {
        this.loading = false
        this.totalCount = res.payload.totalCount
        this.blockedReports = res.payload.blockedData
      },
      (error: HttpErrorResponse) => {
        this.loading = false
      }
    )
  }
  goToReportUpliftDetails(upliftId: string): void {
    this.route.navigate(['reported-uplift-detail'], { queryParams: { upliftId } })
  }

  openConfirmationModel(popUp: TemplateRef<any>, report: any, status: string): void {
    this.selectedReport = report
    this.selectedReport.status = status
    this.modalRef = this.modalService.show(popUp, { ignoreBackdropClick: true, backdrop: 'static', keyboard: true })
  }

  updateUpliftStatus(): void {
    console.log(this.selectedReport)
    if (this.selectedReport?.isResolved) {
      this.toasterService.info(`Uplift is already ${this.selectedReport?.status == 'approve' ? 'blocked' : 'rejected'}`)
      return
    }
    this.modalRef.hide()
    this.allService.updateUpliftStatus(this.selectedReport?._id, this.selectedReport?.status).subscribe((res: any) => {
      this.toasterService.success(res.message)
      this.getAllReports(this.currentPage, this.limit)
    })
  }
}
